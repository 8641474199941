import React, {useContext, useEffect, useRef} from 'react'
import {RobotContext} from "../RobotContext";
import JSMpeg from '@cycjimmy/jsmpeg-player';

class CustomMpegtsSource {
    constructor(backendId, options) {
        this.options = options
        this.backendId = backendId
        console.log('new ts source !', backendId, options)
        this.handleDataPacket = this.handleDataPacket.bind(this);

        this.streaming = true;
        this.completed = false;
        this.established = false;
        this.progress = 0;
        this.canPlay = true
    }

    connect(destination) {
        console.log('ts: connect', destination)
        this.ts2destination = destination
        this.options.customSocket.on(`r2cmr_${this.backendId}`, this.handleDataPacket)
    }

    handleDataPacket(data){
        if(data && !data.channel){
            const framecheck = new Uint8Array(data.chunk)
            console.log(`Received video frame: ${framecheck.length} bytes long: ${framecheck.slice(0,15).join(' ')}`)
            this.ts2destination.write(data.chunk)
        }
    }

    destroy() {
        this.options.customSocket.off(`r2cmr_${this.backendId}`, this.handleDataPacket)
        console.log('ts: destroy')
    }

    start() {
        this.established = true;
        this.progress = 1
        console.log('ts: start')
    } 

    resume() {
        console.log('ts: resume')
        
    }
}

const BackendVideoMPEGTS = ({backend}) => {
    const {socket,styles,robotData,isFullScreen} = useContext(RobotContext)

    const extractAnyTelemetryData = dataPack => {
        if(dataPack.channel === "log"){
            // console.log(dataPack)
        }
        if(dataPack.channel === "log" && dataPack.chunk && dataPack.chunk.message){
            console.log(dataPack)
            // this.appendTemporaryLogData(`${this.strip(dataPack.chunk.timestamp)}: ${this.strip(dataPack.chunk.message)}`)
        }else if(dataPack.channel === "telemetry" && dataPack.chunk){
            console.log(dataPack)
            // this.appendTemporaryLogData(`${this.strip(dataPack.chunk.timestamp)}: ${this.strip(dataPack.chunk.key)}: ${this.strip(dataPack.chunk.value)}`)
        }
    }

    const videoPlayerObject = useRef(null)
    const videoPlayerWrapper = useRef(null)
    const videoPlayerCanvas = useRef(null)

    const handleDataPacket = dataPack => {
        extractAnyTelemetryData(dataPack)
        if(!dataPack.channel && dataPack.chunk){
            const framecheck = new Uint8Array(dataPack.chunk)
            if(framecheck[0] === 0xff && framecheck[1] === 0xd8 ){
                const frame = URL.createObjectURL(new Blob([dataPack.chunk], {type: 'image/jpeg'}))
                imageRef.current.src = frame
                setTimeout(() => {
                    URL.revokeObjectURL(frame)
                }, 500)
            }
        }
    }


    useEffect(() => {
        console.log('creating new video player...',videoPlayerWrapper.current)
        videoPlayerObject.current = new JSMpeg.VideoElement(videoPlayerWrapper.current, backend.robot_backend_id, 'test', {
            source: CustomMpegtsSource,
            canvas: videoPlayerCanvas.current,
            customSocket: socket,
            autoplay: true,
            control: false,
            video: true,
            audio: false,
            decodeFirstFrame: true
            // disableWebAssembly: true,
            // disableGl: true,
            // picMode: true
        });
        return () => {
            videoPlayerObject.current.destroy()
        }
    },[])

    return (
        <div ref={videoPlayerWrapper} className={styles.videoPlayer}>
            <canvas className={styles.videoPlayerCanvas} ref={videoPlayerCanvas}></canvas>
        </div>
    )
}

export default BackendVideoMPEGTS
