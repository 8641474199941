import React, {useContext, useEffect, useState} from 'react'
import {RobotContext} from "./RobotContext";

const Collapsible = ({title, children, openDefault = null, open = null, setOpen = null}) => {
    const {styles,isFullScreen} = useContext(RobotContext)

    if(open === null) {
        [open, setOpen] = useState(openDefault)
    }

    useEffect(() => {
        if(openDefault !== null) {
            setOpen(openDefault)
        }
    }, [openDefault])

    const toggleOpenState = () => {
        setOpen(!open)
    }

    return (
        <div className={styles.niceBox} data-collapsed={!open}>
            <div className={`${styles.collapsibleHeader} ${styles.niceBoxHeader} ${!open ? styles.niceBoxHeaderCollapsed : ''}`} onClick={toggleOpenState}>
                {title}
            </div>
            <div className={`${styles.niceBoxContent} ${open ? styles.collapsibleVisible : styles.collapsibleHidden}`}>
                {children}
            </div>
        </div>
    )
}

export default Collapsible
