import React, {useContext, useEffect, useState} from 'react'
import Collapsible from "./Collapsible";
import BackendCredits from "./backends/BackendCredits";
import BackendQueue from "./backends/BackendQueue";
import BackendControl from "./backends/BackendControl";
import BackendQuest from "./backends/BackendQuest";
import BackendTelemetry from "./backends/BackendTelemetry";
import BackendVideoJpeg from "./backends/BackendVideoJpeg";
import BackendVideoSvg from "./backends/BackendVideoSvg";
import BackendVideoH264 from "./backends/BackendVideoH264";
import BackendVideoMPEGTS from "./backends/BackendVideoMPEGTS";
import MultiPanelHolder from "./backends/MultiPanelHolder";
import {RobotContext} from "./RobotContext";

const RobotControlPanel = () => {
    const {socket, styles, robotData, isFullScreen, robotBackends, robotQueueData, userCredits, hasControl} = useContext(RobotContext)

    return (
        <div className={styles.robotControl}>
            <div className={`${styles.mainVideoAndControls} ${styles.niceBox}`}>

                {!hasControl && (
                    <div className={styles.robotLocked}>
                        <div>
                            View Only Mode
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 2048 2048">
                            <path
                                fill="#bdbdbd"
                                d="M512 788v-35c0-136 54-265 150-362 96-96 226-150 362-150s265 54 362 150c96 96 150 226 150 362v35h-120v-35c0-104-42-203-115-276-74-74-172-115-276-115s-203 42-276 115c-74 74-115 172-115 276v35H514z"
                            ></path>
                            <path
                                fill="#b6b6b6"
                                d="M1462 788h-47v-35c0-104-42-203-115-276-74-74-172-115-276-115s-203 42-276 115c-74 74-115 172-115 276v35h-47v-18c0-119 46-232 129-316 82-84 193-132 310-132s227 47 310 132c82 84 129 197 129 316v18z"
                            ></path>
                            <path
                                fill="#dac27c"
                                d="M466 768h46v-1h120v1h783v-1h120v1h46c23 0 43 9 58 24s24 35 24 58v860c0 23-9 43-24 58s-35 24-58 24H465c-23 0-43-9-58-24s-24-35-24-58V850c0-23 9-43 24-58s35-24 58-24z"
                            ></path>
                            <path
                                fill="#414141"
                                d="M1024 1028c32 0 61 13 82 34s34 50 34 82c0 17-4 33-10 47-5 11-11 21-19 29v228h-1c-1 47-39 84-86 84s-85-37-86-84v-227c-8-9-14-19-19-29-7-15-10-31-10-47 0-32 13-61 34-82s50-34 82-34z"
                            ></path>
                            <path
                                fill="#cfb76e"
                                d="M466 768h1v1024h-1c-23 0-43-9-58-24s-24-35-24-58V850c0-23 9-43 24-58s35-24 58-24zm1115 0h1c23 0 43 9 58 24s24 35 24 58v860c0 23-9 43-24 58s-35 24-58 24h-1V768z"
                            ></path>
                            <path fill="#c7ad95" d="M512 788v-20h120v20z"></path>
                            <path fill="#c2a88f" d="M632 768v20h-47v-20h47z"></path>
                            <path
                                fill="#dcb165"
                                d="M1024 1792H466c-23 0-43-9-58-24s-24-35-24-58V850c0-23 9-43 24-58s35-24 57-24h559v1024z"
                            ></path>
                            <path
                                fill="#212121"
                                d="M1024 1028v504h-1c-47 0-85-37-86-84v-227c-8-9-14-19-19-29-7-15-10-31-10-47 0-32 13-61 34-82s50-34 82-34z"
                            ></path>
                            <path
                                fill="#d4a95b"
                                d="M467 768v1024h-1c-23 0-43-9-58-24s-24-35-24-58V850c0-23 9-43 24-58s35-24 57-24h3z"
                            ></path>
                            <path fill="none" d="M0 0h2048v2048H0z"></path>
                        </svg>
                    </div>
                )}

                <div className={styles.niceBoxHeader}>Video Feed</div>
                <div className={styles.niceBoxContent}>
                    <MultiPanelHolder mode="pictureInPicture">
                        {robotBackends
                            .filter(backend => {
                                return backend.type.match(/SOCKET_VIDEO/)
                            })
                            .map(backend => {
                                switch (backend.type) {
                                    case 'SOCKET_VIDEO_JPEG':
                                        return (
                                            <BackendVideoJpeg key={backend.robot_backend_id}
                                                              backend={backend}></BackendVideoJpeg>
                                        )
                                    case 'SOCKET_VIDEO_SVG':
                                        return (
                                            <BackendVideoSvg key={backend.robot_backend_id}
                                                             backend={backend}></BackendVideoSvg>
                                        )
                                    case 'SOCKET_VIDEO_H264':
                                        return (
                                            <BackendVideoH264 key={backend.robot_backend_id}
                                                              backend={backend}></BackendVideoH264>
                                        )
                                    case 'SOCKET_VIDEO_MPEGTS':
                                        return (
                                            <BackendVideoMPEGTS key={backend.robot_backend_id}
                                                              backend={backend}></BackendVideoMPEGTS>
                                        )
                                }
                            })}
                    </MultiPanelHolder>
                    <div className={styles.overlayControlLeft}>
                        {robotBackends
                            .filter(backend => {
                                return backend.type === "SOCKET_CONTROL" && backend.ui_slot === "CONTROL_BOTTOM_LEFT"
                            })
                            .map(backend => {
                                return (
                                    <BackendControl key={backend.robot_backend_id} backend={backend}></BackendControl>
                                )
                            })}
                    </div>
                    <div className={styles.overlayControlRight}>
                        {robotBackends
                            .filter(backend => {
                                return backend.type === "SOCKET_CONTROL" && backend.ui_slot === "CONTROL_BOTTOM_RIGHT"
                            })
                            .map(backend => {
                                return (
                                    <BackendControl key={backend.robot_backend_id} backend={backend}></BackendControl>
                                )
                            })}
                    </div>
                </div>
            </div>
            <div className={styles.mainSidebar}>
                <Collapsible title="Controls" openDefault={true}>
                    <MultiPanelHolder>
                        {robotBackends
                            .filter(backend => {
                                return backend.type === "SOCKET_CONTROL" && backend.ui_slot === "CONTROL_EXTRA"
                            })
                            .map(backend => {
                                return (
                                    <BackendControl key={backend.robot_backend_id} backend={backend}></BackendControl>
                                )
                            })}
                    </MultiPanelHolder>
                </Collapsible>
                <Collapsible title={`Your Credits (${userCredits})`} openDefault={!isFullScreen}>
                    <BackendCredits></BackendCredits>
                </Collapsible>
                <Collapsible title="Quests" openDefault={!isFullScreen}>
                    <MultiPanelHolder>
                        {robotBackends
                            .filter(backend => {
                                return backend.type === "SOCKET_QUEST"
                            })
                            .map(backend => {
                                return (
                                    <BackendQuest key={backend.robot_backend_id} backend={backend}></BackendQuest>
                                )
                            })}
                    </MultiPanelHolder>
                </Collapsible>
                <Collapsible title={`Users (${robotQueueData?.length})`} openDefault={!isFullScreen}>
                    <BackendQueue></BackendQueue>
                </Collapsible>
                <Collapsible title="Telemetry & Logs" openDefault={!isFullScreen}>
                    <MultiPanelHolder>
                        {robotBackends
                            .filter(backend => {
                                return backend.type === "SOCKET_TELEMETRY"
                            })
                            .map(backend => {
                                return (
                                    <BackendTelemetry key={backend.robot_backend_id}
                                                      backend={backend}></BackendTelemetry>
                                )
                            })}
                    </MultiPanelHolder>
                </Collapsible>
            </div>
        </div>
    )
}

export default RobotControlPanel


/*
json.backends.forEach(backend => {
                console.log(`Backend:`)
                console.log(` - Type: ${backend.type}`)
                console.log(` - ID: ${backend.robot_backend_id}`)
                console.log(` - User ID: ${streamUserId}`)
                console.log(` - Status: CONNECTING`)
                console.log(` `)

                let initializedBackend

                switch (backend.type) {
                    case "SOCKET_VIDEO_JPEG":
                        initializedBackend = new SocketVideoJpeg({
                            socket: socket,
                            backend: backend,
                            dom: this.backendVideosTarget
                        })
                        break;
                    case "SOCKET_VIDEO_SVG":
                        initializedBackend = new SocketVideoSvg({
                            socket: socket,
                            backend: backend,
                            dom: this.backendVideosTarget
                        })
                        break;
                    case "SOCKET_VIDEO_H264":
                        initializedBackend = new SocketVideoH264({
                            socket: socket,
                            backend: backend,
                            dom: this.backendVideosTarget
                        })
                        break;
                    case "SOCKET_TELEMETRY":
                        initializedBackend = new SocketTelemetry({
                            socket: socket,
                            backend: backend,
                            dom: this.backendTelemetriesTarget
                        })
                        break;
                    case "SOCKET_CONTROL":
                        // We can customise where controls sit
                        let thisControlDom = null
                        switch (backend.ui_slot) {
                            case 'CONTROL_BOTTOM_LEFT':
                                thisControlDom = this.backendMainControlsTarget
                                break;
                            case 'CONTROL_BOTTOM_RIGHT':
                                // todo
                                break;
                            case 'AUTO':
                            case 'CONTROL_EXTRA':
                            default:
                                thisControlDom = this.backendControlsTarget
                        }
                        initializedBackend = new SocketControl({
                            socket: socket,
                            backend: backend,
                            dom: thisControlDom
                        })
                        break;
                    case "SOCKET_QUEST":
                        initializedBackend = new SocketQuest({
                            socket: socket,
                            backend: backend,
                            dom: this.backendQuestsTarget,
                            streamUserId: streamUserId
                        })
                        break;
                }

                if (initializedBackend) {
                    this.initializedBackends.push(initializedBackend)
                }
            })
 */
