import React, {useContext, useEffect, useState} from 'react'
import {RobotContext} from "../RobotContext";

const BackendVideoH264 = ({backend}) => {
    const {socket,styles,robotData,isFullScreen} = useContext(RobotContext)

    useEffect(() => {
        // socket.on("robot_queue", setQueueData)
        return () => {
            // socket.off("robot_queue", setQueueData)
        }
    },[])

    return (
        <div className={styles.backendVideoMediaWrapper}>
            {JSON.stringify(backend)}
        </div>
    )
}

export default BackendVideoH264
