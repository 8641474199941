import React, {useContext, useEffect, useState} from 'react'
import {RobotContext} from "../RobotContext";
import { Joystick } from 'react-joystick-component';

const BackendControl = ({backend}) => {
    const {socket,styles,robotData,isFullScreen,fetchUpdatedRobotDataRef} = useContext(RobotContext)

    const [joystickDirection, setJoystickDirection] = useState('C')
    useEffect(() => {
        socket.emit(`cmr2r_${backend.robot_backend_id}`, {
            action: 'control',
            message: 'joystick',
            direction: joystickDirection
        })
    },[joystickDirection])

    switch(backend?.backend_metadata?.type){
        case 'joystick':
            const getCardinalDirection = (pos) => {
                const isRoughlyCenter = (number) => {
                    return (number > -0.2 && number < 0.2)
                }

                if(isRoughlyCenter(pos.x) && isRoughlyCenter(pos.y)){
                    return "C"
                }

                if(isRoughlyCenter(pos.x)){
                    if(pos.y > 0) {
                        return "N"
                    }else{
                        return "S"
                    }
                }

                if(isRoughlyCenter(pos.y)){
                    if(pos.x < 0) {
                        return "W"
                    }else{
                        return "E"
                    }
                }

                if(pos.y > 0) {
                    if(pos.x < 0) {
                        return "NW"
                    }else{
                        return "NE"
                    }
                }else{
                    if(pos.x < 0) {
                        return "SW"
                    }else{
                        return "SE"
                    }
                }
            }

            const joyStickHandleMove = pos => {
                setJoystickDirection(getCardinalDirection(pos))
            }
            const joyStickHandleStop = pos => {
                setJoystickDirection('C')
            }
            return (
                <div className={styles.backendControlJoystick}>
                    <Joystick size={150} stickSize={70} throttle={50} baseColor="rgba(255,255,255,0.13)" stickColor="rgba(255,255,255,0.63)" move={joyStickHandleMove} stop={joyStickHandleStop}></Joystick>
                </div>
            )
        case 'button':
            return (
                <button className={styles.backendControlButton}
                     onClick={async (e) => {
                         const response = await socket.emitWithAck(`cmr2r_${backend.robot_backend_id}`, {
                             action: 'special_button',
                             message: backend.backend_metadata.message
                         })
                         // this likely results in a credit change / notice.
                         // lets fetch latest data then
                         fetchUpdatedRobotDataRef.current.callback()
                    }}>
                    {backend.backend_metadata.label} ({backend.backend_metadata.credits} credits)
                </button>
            )
    }
    return (
        <div className={styles.backendControl}>
            Unknown: {JSON.stringify(backend)}
        </div>
    )
}

export default BackendControl
