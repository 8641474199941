
const digest = '63bf08f18ca017285dcad217a4e09f1fe714b2e6fb19553648777f6d882c0a82';
const classes = {"background":"_background_j6m4k_1"};
const css = `._background_j6m4k_1 {
  background-color: #000;
  opacity: 0.8;
  background-image: linear-gradient(30deg, #1f2022 12%, transparent 12.5%, transparent 87%, #1f2022 87.5%, #1f2022), linear-gradient(150deg, #1f2022 12%, transparent 12.5%, transparent 87%, #1f2022 87.5%, #1f2022), linear-gradient(30deg, #1f2022 12%, transparent 12.5%, transparent 87%, #1f2022 87.5%, #1f2022), linear-gradient(150deg, #1f2022 12%, transparent 12.5%, transparent 87%, #1f2022 87.5%, #1f2022), linear-gradient(60deg, #000001 25%, transparent 25.5%, transparent 75%, #000001 75%, #000001), linear-gradient(60deg, #000001 25%, transparent 25.5%, transparent 75%, #000001 75%, #000001);
  background-size: 20px 35px;
  background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;
}`;

(function() {
  if (typeof document !== "undefined" && !document.getElementById(digest)) {
    var ele = document.createElement('style');
    ele.id = digest;
    ele.textContent = css;
    document.head.appendChild(ele);
  }
})();

export default classes;
export { css, digest, classes };
  