import React, {useContext, useEffect, useState} from 'react'
import Collapsible from "./Collapsible";;
import {RobotContext} from "./RobotContext";
import MultiPanelHolder from "./backends/MultiPanelHolder";

const RobotAdminBackend = ({type, robot_backend_id, robot_id, ui_size, ui_slot}) => {
    return (
        <div>{robot_backend_id} / {type} / {ui_size} / {ui_slot}</div>
    )
}
const RobotAdminPanel = () => {
    const {styles, robotData, isFullScreen, robotBackends, robotQueueData, userCredits, hasControl} = useContext(RobotContext)

    return (
        <div className={styles.robotControl}>
            <div className={`${styles.mainVideoAndControls} ${styles.niceBox}`}>
                <div className={styles.niceBoxHeader}>Video Feed</div>
                <div className={styles.niceBoxContent}>
                    <MultiPanelHolder mode="pictureInPicture">
                        {robotBackends
                            .filter(backend => {
                                return backend.type.match(/SOCKET_VIDEO/)
                            })
                            .map(backend => {
                                return (
                                    <RobotAdminBackend robot_backend_id={backend.robot_backend_id} type={backend.type} ui_size={backend.ui_size} ui_slot={backend.ui_slot} />
                                )
                            })}
                    </MultiPanelHolder>
                    <div className={styles.overlayControlLeft}>
                        {robotBackends
                            .filter(backend => {
                                return backend.type === "SOCKET_CONTROL" && backend.ui_slot === "CONTROL_BOTTOM_LEFT"
                            })
                            .map(backend => {
                                return (
                                    <RobotAdminBackend robot_backend_id={backend.robot_backend_id} type={backend.type} ui_size={backend.ui_size} ui_slot={backend.ui_slot} />
                                )
                            })}
                    </div>
                    <div className={styles.overlayControlRight}>
                        {robotBackends
                            .filter(backend => {
                                return backend.type === "SOCKET_CONTROL" && backend.ui_slot === "CONTROL_BOTTOM_RIGHT"
                            })
                            .map(backend => {
                                return (
                                    <RobotAdminBackend robot_backend_id={backend.robot_backend_id} type={backend.type} ui_size={backend.ui_size} ui_slot={backend.ui_slot} />
                                )
                            })}
                    </div>
                </div>
            </div>
            <div className={styles.mainSidebar}>
                <Collapsible title="Controls" openDefault={true}>
                    <MultiPanelHolder>
                        {robotBackends
                            .filter(backend => {
                                return backend.type === "SOCKET_CONTROL" && backend.ui_slot === "CONTROL_EXTRA"
                            })
                            .map(backend => {
                                return (
                                    <RobotAdminBackend robot_backend_id={backend.robot_backend_id} type={backend.type} ui_size={backend.ui_size} ui_slot={backend.ui_slot} />
                                )
                            })}
                    </MultiPanelHolder>
                </Collapsible>
                <Collapsible title={`Your Credits (${userCredits})`} openDefault={!isFullScreen}>
                    credits
                </Collapsible>
                <Collapsible title="Quests" openDefault={!isFullScreen}>
                    <MultiPanelHolder>
                        {robotBackends
                            .filter(backend => {
                                return backend.type === "SOCKET_QUEST"
                            })
                            .map(backend => {
                                return (
                                    <RobotAdminBackend robot_backend_id={backend.robot_backend_id} type={backend.type} ui_size={backend.ui_size} ui_slot={backend.ui_slot} />
                                )
                            })}
                    </MultiPanelHolder>
                </Collapsible>
                <Collapsible title={`Users (${robotQueueData?.length})`} openDefault={!isFullScreen}>
                    queue
                </Collapsible>
                <Collapsible title="Telemetry & Logs" openDefault={!isFullScreen}>
                    <MultiPanelHolder>
                        {robotBackends
                            .filter(backend => {
                                return backend.type === "SOCKET_TELEMETRY"
                            })
                            .map(backend => {
                                return (
                                    <RobotAdminBackend robot_backend_id={backend.robot_backend_id} type={backend.type} ui_size={backend.ui_size} ui_slot={backend.ui_slot} />
                                )
                            })}
                    </MultiPanelHolder>
                </Collapsible>
            </div>
        </div>
    )
}

export default RobotAdminPanel
