import React, {useContext, useEffect, useRef, useState} from 'react'
import {RobotContext} from "../RobotContext";
import moment from "moment";

const BackendTelemetry = ({backend}) => {
    const {socket,styles,robotData,isFullScreen} = useContext(RobotContext)

    const [telemetryData, setTelemetryData] = useState({})
    const telemetryDataRef = useRef(null);
    telemetryDataRef.current = telemetryData

    const handleDataPacket = dataPack => {
        if(dataPack.channel && dataPack.channel === "telemetry"){
            setTelemetryData({
                ...telemetryDataRef.current,
                [dataPack.chunk.key]: dataPack.chunk.value
            })
        }else if(dataPack.channel && dataPack.channel === "log"){
            let existingLogEntries = telemetryDataRef.current.log || []
            if(existingLogEntries && existingLogEntries.length > 15){
                existingLogEntries.pop()
            }
            existingLogEntries.unshift({
                timestamp: Date.now(),
                serverMessage: dataPack.chunk.message
            })
            setTelemetryData({
                ...telemetryDataRef.current,
                log: existingLogEntries
            })
        }
    }

    useEffect(() => {
        socket.on(`r2cmr_${backend.robot_backend_id}`, handleDataPacket)
        return () => {
            socket.off(`r2cmr_${backend.robot_backend_id}`, handleDataPacket)
        }
    },[])

    return (
        <div className={styles.telemetry}>
            {Object.entries(telemetryData).map((telemetry, tindex) => {
                if(Array.isArray(telemetry[1])){
                    return (
                        <div key={`tel-${tindex}`}>
                            <ul className={styles.eventList}>
                            {telemetry[1].map((log, index) => {
                                return (
                                    <li key={`log-${index}`}>
                                        <span className={styles.eventMessage}>
                                            {log.serverMessage}
                                        </span>
                                        <span className={styles.eventTimestamp}>
                                            {moment(log.timestamp).fromNow()}
                                        </span>
                                    </li>
                                )
                            })}
                            </ul>
                        </div>
                    )
                }else{
                    return (
                        <div key={`tel-${tindex}`}>
                            {telemetry[0]}: {telemetry[1]}
                        </div>
                    )
                }
            })}
        </div>
    )
}

export default BackendTelemetry
