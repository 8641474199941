import React, {useState, useEffect} from 'react'

function useData(url) {
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)
    useEffect(() => {
        if(!url)return
        let ignore = false
        fetch(url)
            .then(response => response.json())
            .then(json => {
                if (json && json.success && !ignore) {
                    setData(json)
                }else if(!ignore){
                    setError(json)
                }
            })
            .catch(e=>{
                setError(e)
            })
        return () => {
            ignore = true
        }
    }, [url])
    return {data,error}
}

export default useData
