import React, {useContext, useEffect, useState} from 'react'
import {RobotContext} from "../RobotContext";
import Collapsible from "../Collapsible";

const BackendQueue = () => {
    const {socket,styles,userId,isFullScreen,userCredits,robotQueueData} = useContext(RobotContext)
    // const [queueData, setQueueData] = useState(robotConnectionApiData.queue)
    //
    // useEffect(() => {
    //     socket.on("robot_queue", setQueueData)
    //     return () => {
    //         socket.off("robot_queue", setQueueData)
    //     }
    // },[])

    if(!robotQueueData){
        return null
    }

    return (
        <div className={styles.queue}>
            <ul>
            {robotQueueData.map(queue => {
                let usernameString = `${queue.username} is `
                if(queue.user_id === userId){
                    usernameString = 'YOU are'
                }
                return (
                    <li key={`queue-${queue.user_id}`} className={queue.user_id === userId ? styles.queueMyEntry : null}>
                        {queue.active ? (
                            <>
                                #{queue.position}: {usernameString} in control.
                                {robotQueueData.length > 1 && (
                                    <>
                                      &nbsp;  {queue.time_remaining} seconds left before next user.
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                #{queue.position}: {usernameString} {queue.view_only ? 'viewing the stream.' : 'waiting for control.'}
                            </>
                        )}

                    </li>
                )
            })}
                {/*robotQueueData.length === 1 && (
                    <li>
                        There are no other users waiting for robot control.
                    </li>
                )*/}
            </ul>
        </div>
    )
}

export default BackendQueue
