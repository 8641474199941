import React, {useContext} from 'react'
import { RobotContext } from "./RobotContext"

const RobotMissionBriefHeader = ({isExpanded, robotConnectionState, toggleFullScreen, disconnectRobot}) => {
    const {styles,robotData,isFullScreen} = useContext(RobotContext)
    return (
        <div className={styles.missionBriefHeader}>
            <div className={styles.missionBriefHeaderText}>
                Robot Overview
            </div>
            <div className={styles.missionBriefHeaderActions}>
                {robotConnectionState === "CONNECTED" && (
                    <button className={styles.disconnectButton} onClick={e => {
                        e.stopPropagation()
                        disconnectRobot()
                        return false
                    }}>Disconnect</button>
                )}
                {(false) && (
                    <>
                        {isFullScreen ? (
                            <button onClick={e=>{
                                e.stopPropagation()
                                toggleFullScreen()
                                return false
                            }}>Leave Full Screen</button>
                        ):(
                            <button onClick={e=>{
                                e.stopPropagation()
                                toggleFullScreen()
                                return false
                            }}>Go Full Screen</button>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default RobotMissionBriefHeader
