import * as React from 'react'
import ReactDOM from 'react-dom/client'
import Robot from "./Robot"

const initReactPage = () => {
    const element = document.getElementById('js-robot-ui')
    if(element) {
        const root = ReactDOM.createRoot(element)
        const robotConfig = JSON.parse(element.dataset.robotConfig)
        root.render(<Robot robotConfig={robotConfig} />)
    }
}

document.addEventListener("turbolinks:load", initReactPage)
initReactPage()
