import React, {useContext, useEffect, useRef} from 'react'
import {RobotContext} from "../RobotContext";

const BackendVideoJpeg = ({backend}) => {
    const {socket,styles,robotData,isFullScreen} = useContext(RobotContext)

    const extractAnyTelemetryData = dataPack => {
        if(dataPack.channel === "log"){
            // console.log(dataPack)
        }
        if(dataPack.channel === "log" && dataPack.chunk && dataPack.chunk.message){
            console.log(dataPack)
            // this.appendTemporaryLogData(`${this.strip(dataPack.chunk.timestamp)}: ${this.strip(dataPack.chunk.message)}`)
        }else if(dataPack.channel === "telemetry" && dataPack.chunk){
            console.log(dataPack)
            // this.appendTemporaryLogData(`${this.strip(dataPack.chunk.timestamp)}: ${this.strip(dataPack.chunk.key)}: ${this.strip(dataPack.chunk.value)}`)
        }
    }

    const imageRef = useRef(null);

    const handleDataPacket = dataPack => {
        extractAnyTelemetryData(dataPack)
        if(!dataPack.channel && dataPack.chunk){
            const framecheck = new Uint8Array(dataPack.chunk)
            if(framecheck[0] === 0xff && framecheck[1] === 0xd8 ){
                const frame = URL.createObjectURL(new Blob([dataPack.chunk], {type: 'image/jpeg'}))
                imageRef.current.src = frame
                setTimeout(() => {
                    URL.revokeObjectURL(frame)
                }, 500)
            }
        }
    }

    useEffect(() => {
        socket.on(`r2cmr_${backend.robot_backend_id}`, handleDataPacket)
        return () => {
            socket.off(`r2cmr_${backend.robot_backend_id}`, handleDataPacket)
        }
    },[])

    return (
        <img src={null} ref={imageRef} className={styles.fullImage} />
    )
}

export default BackendVideoJpeg
