import React, {useContext, useEffect, useRef, useState} from 'react'
import {RobotContext} from "../RobotContext";

const BackendQuest= ({backend}) => {
    const {socket,styles,streamUserId,robotData,isFullScreen,robotQuestData} = useContext(RobotContext)

    //
    // const [robotQuestData, setrobotQuestData] = useState(backend.quest_data)
    //
    // const robotQuestDataRef = useRef(null)
    // robotQuestDataRef.current = robotQuestData
    //
    // const handleDataPacket = dataPack => {
    //     if(dataPack.channel && dataPack.channel === "quest" && dataPack?.api_data?.quest_step_completed && dataPack.api_data.messages_for_users) {
    //         dataPack.api_data.messages_for_users.forEach(m => {
    //             if (m.user_id === streamUserId) {
    //                 /*
    //                   timestamp:
    //                   user_id:
    //                   message:
    //                   credit_amount:
    //                   quest_id:
    //                   quest_name:
    //                   full_quest_completed:
    //                   seconds_remaining:
    //                  */
    //                 // todo: fetch this updated status from our API rather than getting pushed through a socket message:
    //                 if (m.quest_id && m.quest_step_id) {
    //                     // update the status of the step in the UI to completed
    //                     if(robotQuestDataRef.current.quest_id === m.quest_id){
    //                         let updatedrobotQuestData = {
    //                             ...robotQuestDataRef.current
    //                         }
    //                         updatedrobotQuestData.steps.forEach((step, index) => {
    //                             if (step.step_id === m.quest_step_id) {
    //                                 updatedrobotQuestData.steps[index].completed = true
    //                             }
    //                         })
    //                         setrobotQuestData(updatedrobotQuestData)
    //                     }
    //                 }
    //                 // const logEntry = document.createElement('div')
    //                 // logEntry.innerText = `DEBUG: ${this.strip(m.timestamp)}: ${this.strip(m.message)}`
    //                 // this.logDom.prepend(logEntry)
    //             }
    //         })
    //     }
    // }
    //
    // useEffect(() => {
    //     socket.on(`r2cmr_${backend.robot_backend_id}`, handleDataPacket)
    //     return () => {
    //         socket.off(`r2cmr_${backend.robot_backend_id}`, handleDataPacket)
    //     }
    // },[])

    if(!robotQuestData || !robotQuestData[backend.robot_backend_id]){
        return null
    }

    const thisQuestData = robotQuestData[backend.robot_backend_id]

    return (
        <div className={styles.quest}>
            <div className={styles.questTitle}>
                Quest: <strong>{thisQuestData.name}</strong> <br/>
                Time limit: {parseInt(thisQuestData.quest_duration_minutes,10)} minutes. <br/>
                Steps: {thisQuestData.steps.length} steps <br/>
                Available: This robot has {thisQuestData.available_credits} credits to give away. <br/>
                {thisQuestData.credits_on_full_completion > 0 && (
                    <>
                        Credits: Get +{thisQuestData.credits_on_full_completion} credits when completing all steps. <br/>
                    </>
                )}
            </div>
            <ul className={styles.eventList}>
                {thisQuestData.steps.map(step => {
                    return (
                        <li key={step.step_id}>
                            <span className={styles.eventMessage}>
                                Step: {step.name}
                                {step.credits_on_step_completion > 0 && (
                                    <>
                                        (+{step.credits_on_step_completion} credits)
                                    </>
                                )}
                            </span>
                            <span className={styles.eventTimestamp}>
                                Completed: {step.completed ? 'YES' : 'NO'}
                            </span>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default BackendQuest
