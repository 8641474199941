import React, {useContext, useEffect, useState} from 'react'
import {RobotContext} from "../RobotContext";

const MultiPanelHolder = ({children}) => {
    const {styles,isFullScreen} = useContext(RobotContext)
    const [focusedChildElement, setFocusedChildElement] = useState(0)

    return (
        <div className={styles.multiPanelHolder}>
            {children.map((child, index) => {
                return (
                    <div
                        className={`${styles.multiPanelChild} ${focusedChildElement === index ? styles.multiPanelChildFocused : styles.multiPanelChildHidden}`}
                        key={`child-${index}`}
                        onClick={() => {
                            if(focusedChildElement !== index) {
                                // pump index.
                                let newIndexToFocus = focusedChildElement + 1
                                if(newIndexToFocus > (children.length - 1) ){
                                    newIndexToFocus = 0
                                }
                                setFocusedChildElement(newIndexToFocus)
                            }
                        }}
                    >{child}</div>
                )
            })}
        </div>
    )
}

export default MultiPanelHolder
