import React,{useContext} from 'react'
import { RobotContext } from "./RobotContext"

const RobotMissionBrief = ({robotConnectionState, connectRobot, disconnectRobot, toggleFullScreen}) => {
    const {styles,robotData,isFullScreen} = useContext(RobotContext)
    return (
        <div className={styles.missionBriefWrapper}>
            <div>
                <ul>
                    Robot Name: <strong>{robotData.name}</strong>
                </ul>
                <ul>
                    Description: <strong>{robotData.description}</strong>
                </ul>
                <ul>
                    Location: <strong>{robotData.location_name}</strong>
                </ul>
                <ul>
                    Creator: <strong>{robotData.creator_name}</strong>
                </ul>
                <ul>
                    Total Connected Users: <strong>{robotData.queue_length}</strong>
                </ul>
                <ul>
                    Robot Status:
                    {robotData.state === "ui-online" && (
                        <strong>
                            Online
                        </strong>
                    )}
                    {robotData.state === "ui-coming-soon" && (
                        <strong>
                            Coming Soon
                        </strong>
                    )}
                    {robotData.state === "ui-offline" && (
                        <strong>
                            Offline
                        </strong>
                    )}
                </ul>
            </div>
            <div>
                <ul>
                    <li><strong>Robot Pricing:</strong></li>
                    <li>
                        {robotData.signed_in ? (
                            <>
                                You currently have <strong>{robotData.current_credits}</strong> credits.
                            </>
                        ) : (
                            <>
                                Please sign in first before controlling this robot.
                            </>
                        )}
                    </li>
                    <li>
                        To control this robot it costs: <strong>{robotData.credit_control_cost_summary}</strong>
                    </li>
                    {robotData.quests.length > 0 ? (
                        <li>
                            This robot has <strong>{robotData.quests.length}</strong> available quests: <br/>
                            {robotData.quests.map(quest => (
                                <>
                                    - "{quest.name}" earn <strong>{quest.total_possible_credits}</strong> credits.<br/>
                                </>
                            ))}
                        </li>
                    ) : ''}
                    {robotData.custom_actions.length > 0 ? (
                        <li>
                            This robot has <strong>{robotData.custom_actions.length}</strong> custom actions: <br/>
                            {robotData.custom_actions.map(action => (
                                <>
                                    - "{action.label}" uses <strong>{action.credits}</strong> credits.<br/>
                                </>
                            ))}
                        </li>
                    ) : ''}
                </ul>
                {robotData.state === "ui-online" ? (
                    <div>
                        {robotConnectionState === "DISCONNECTED" && (
                            <>
                                <button  onClick={() => {
                                    if(!isFullScreen){
                                        toggleFullScreen()
                                    }
                                    connectRobot()
                                }}>Connect To Robot</button>
                            </>
                        )}
                        {robotConnectionState === "CONNECTING" && (
                            <button onClick={() => {
                            }}>Connecting.....</button>
                        )}
                        {robotConnectionState === "CONNECTED" && (
                            <button className={styles.disconnectButton} onClick={() => {
                                disconnectRobot()
                            }}>Disconnect</button>
                        )}
                        {robotConnectionState === "ERROR" && (
                            <button onClick={() => {
                                disconnectRobot()
                            }}>Error Connecting</button>
                        )}
                    </div>
                ) : (
                    <div>
                        Sorry this robot is offline, please check back later to control this robot.
                    </div>
                )}
            </div>
            <div className={styles.imageWrapper}>
                <img src={robotData.image} width={300}/>
            </div>
        </div>
    )
}

export default RobotMissionBrief
