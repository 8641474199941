import React, {useContext, useEffect, useState} from 'react'
import {RobotContext} from "../RobotContext";
import moment from "moment";

const BackendCredits = () => {
    const {socket,styles,robotData,isFullScreen,userCredits,userNotices} = useContext(RobotContext)

    return (
        <div className={styles.credits}>
            <ul className={styles.eventList}>
            {userNotices.map(notice => {
                return (
                    <li key={notice.id}>
                        <span className={styles.eventMessage}>
                            {notice.credit_change > 0 && `Received ${notice.credit_change} credits. `}
                            {notice.credit_change < 0 && `Used ${Math.abs(notice.credit_change)} credits. `}
                            {typeof notice.credit_new_total !== 'undefined' && `New total is ${notice.credit_new_total} credits. `}
                            {notice.message}
                        </span>
                        <span className={styles.eventTimestamp}>
                            {moment(notice.timestamp).fromNow()}
                        </span>
                    </li>
                )
            })}
            </ul>
        </div>
    )
}

export default BackendCredits
